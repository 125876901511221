[data-mea-type="meaArticle"] {
  ul, ol, dl {
    padding-inline-start: 40px;
  }
   color: $shade9;
  font-family: $fontFamilyRegular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #ffffff;
  }


  h1 {
    color: #FFF;
    font-family: $fontFamilyRegular !important;
    font-size: 38px !important;
    font-weight: 700 !important;
    line-height: normal !important;
    letter-spacing: -1.52px;
  }


}