.accordion {
  .accordion-item {
    background-color: transparent;
    border: none;
    margin-bottom: 12px;
    .accordion-header {
      .accordion-button {
        border-radius: 12px;
        background: $shade0;
        color: $shade7;
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
        box-shadow: none;
        .faqIcon {
          display: flex;
          width: 30px;
          height: 30px;
          padding: 5px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: $greenInvisible;
          margin-right: 6px;
        }
        &[aria-expanded="true"] {
          background: $shade1;
          .faqIcon {
            .iconStackOut {
              color: $primary;
              opacity: 1;
            }
          }
        }
      }

    }
    .accordion-collapse {
      margin: 8px 0;


        .accordion-body {
          border-radius: 12px;
          background: $shade1;
          display: flex;
          padding: 16px;
          align-items: center;
          align-self: stretch;
          color: $shade9;
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
        }



    }
  }
}

.accordion-button::after {
  flex-shrink: 0;
  background-image: url('../../../../public/images/icons/icon-arrow-faq.png');
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../../../public/images/icons/icon-arrow-faq.png');
}