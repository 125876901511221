//MEAINDICATOR

#meaindicator {
  //position: fixed;
  position: absolute;
  top: 0%;
  z-index: 9999;
  text-indent: -10000px;
  left: 0 !important;
  right: 0 !important;
}


.md-progress {
  position: relative;
  display: block;
  width: 100%;
  height: 5px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #eee;
  -webkit-box-shadow: none;
  box-shadow: none;

  .indeterminate {
    background-color: #1695E7;

  }
  .indeterminate:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: inherit;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65,0.815,0.735,0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65,0.815,0.735,0.395) infinite;
    will-change: left,right;
  }

  .indeterminate:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: inherit;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.165,0.84,0.44,1) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.165,0.84,0.44,1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
    will-change: left,right;
  }
}

.primary-color-dark {
  background-color: #6AB9EC;
}


@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}


//SPINNER
.meaindicator {
  .sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 10px auto;
  }

  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #f8f9fad9 !important;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }

  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
}

@media (max-width: 600px) {
  .meaindicator.mainIndicator {
    display: none;
  }
}