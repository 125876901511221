.modal-title::first-letter {
  text-transform: uppercase;
}

.modal-dialog-full-width {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;

}

.modal-dialog-no-header {
  .meaDialogHeader {
    display: none !important;
  }

}

.modal-dialog-no-footer {
  .meaDialogFooter {
    display: none;
  }
}

.modal-dialog-no-padding {
  .modal-body {
    padding: 0px !important;
  }
}

.modal-dialog-full-height {
  height: 100vh !important;
  max-height: none !important;
  margin: 0 !important;
  padding: 0 !important;

  .meaDialogContent {
    height: 100vh !important;
    max-height: none !important;
  }

  .meaDialogBody {
    height: 100%;
  }
}

.modal-content-full-width {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
  background-color: #ececec !important
}

.modal-header-full-width {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width {
  border-top: 1px solid #9ea2a2 !important;
}

//UI DIALOG
.ui-dialog {
  .ui-dialog-title {
    float: left;
    margin: 0 auto;
    width: auto;
    //line-height: 3;
    //height: 38px;
    line-height: normal;
    height: 28px;
  }

  .ui-widget-header {
    font-size: 24px;
    font-weight: 700;
    //padding-bottom: 20px;
    //padding-right: 30px;
    padding: 9px 15px;

    .btn {
      //padding: 6px !important;
      padding: 0 !important;

      i {
        //font-size: 22px;
        font-size: inherit;
        padding: 0;
        vertical-align: middle;
      }
    }

    .btn, .ui-dialog-titlebar-close {
      margin-left: 5px;
      text-align: center;
      float: right;
      border-radius: 2px;
      font-weight: 600;
      margin-right: 0 !important;
      //height: 38px;
      //width: 38px;
      //line-height: normal;
      height: 28px;
      width: 28px;
      line-height: inherit;
      font-size: initial;
    }


    .ui-dialog-titlebar-close {
      right: auto;
      top: auto;
      position: relative;
      margin-top: 0;
      line-height: 1;
      overflow: hidden;
      vertical-align: middle;
      display: inline-block;
      border: 1px solid;
      padding: 0;

      text-indent: 0;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-color: transparent;
      background-image: none;
      font-size: 0;
      word-wrap: break-word;
      background-color: #f4f4f4;
      color: #444;
      border-color: #ddd;
    }

    .ui-dialog-titlebar-close:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }

    .btn-default > i, .btn-info > i, .btn-primary > i, .btn-secondary > i, .btn-outline > i, .btn-success > i, .btn-danger > i, .btn-system > i {
      padding-right: 0;
    }
  }


  //.ui-dialog-titlebar-close:after {
  //  content: '\e014';
  //  font-size: 30px;
  //  line-height: 1;
  //  font-weight: 100;
  //  text-align: center;
  //  width: 100%;
  //  position: relative;
  //  top: 1px;
  //  display: inline-block;
  //  font-family: 'Glyphicons Halflings';
  //  font-style: normal;
  //  font-weight: normal;
  //  line-height: 1;
  //  -webkit-font-smoothing: antialiased;
  //  -moz-osx-font-smoothing: grayscale;
  //}

  .ui-dialog-titlebar-close:before {
    content: '\E014';
    font-size: inherit;
    text-align: center;
    width: 100%;
    position: relative;
    font-size: 13px;
    top: 0;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ui-dialog-titlebar-close:after {
    display: none;
  }
}

.ui-dialog-title {
  float: left;
  margin: 0 auto;
  width: auto;
  line-height: 3;
  height: 38px;
}


//DIALOG FULLSCREEN
.mea-fullscreen {
  overflow-y: auto;
  width: 100vw !important;
  height: 100vh !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  .ui-dialog-titlebar {
    width: 100%;
    position: fixed;
    z-index: 1001;
  }

  .ui-dialog-content {
    max-height: 100% !important;
    overflow: visible;
    margin: 0 auto;
    padding-top: 100px;
    //text-align: center;
    //background: blue;
    //height: 100% !important;
    embed {
      //height: 90vh !important;
    }
  }

  .mea-dialog-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  #fileItem {
    margin-bottom: 200px;
  }

  .ui-widget-header .mea-nav-submenu {
    display: none;
  }

  .sidebar-fixed {
    padding-top: 15px;
    //top: 125px;
    width: 265px;
  }

  .ui-resizable-handle {
    visibility: hidden;
  }

  .mea-dialog-container {
    .sidebar {
      display: table-cell !important;
    }

    .mea-box > div:first-child {
      width: 75% !important;
    }
  }
}

//not work in scss !!!
//:fullscreen {
//  .mea-fullscreen;
//}
//
//:-moz-full-screen {
//  .mea-fullscreen;
//}
//
//:-ms-fullscreen {
//  .mea-fullscreen;
//}
//
//:-webkit-full-screen {
//  .mea-fullscreen;
//}

.mea-maximized {
  width: 100vw !important;
  height: 100vh !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  overflow-y: auto;
  //display: table !important;
  .ui-resizable-handle {
    visibility: hidden;
  }

  .sidebar-fixed {
    padding-top: 15px;
    //top: 110px;
    width: 265px;
  }

  .ui-widget-header .mea-nav-submenu {
    display: none;
  }

  .ui-dialog-content {
    max-height: 100% !important;
    //margin-top: 90px;
    padding-top: 100px;
    overflow: visible;
    margin: 0 auto;
    text-align: center;

    > div:first-child > div:nth-child(2) {
      //height: 100vh;
      //margin-top: 90px;
    }

    .mea-dialog-container {
      padding-top: 90px;
    }

    embed {
      height: 90vh !important;
      padding-bottom: 100px;
    }
  }

  .mea-dialog-footer {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: inherit;
  }

  #fileItem {
    //margin-bottom: 200px;
  }

  .ui-dialog-titlebar {
    position: fixed;
    //position: relative;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
  }
}

.mea-dialog-header-btn {
  float: right;
  margin: 5px 5px 5px 0 !important;
  display: inline-block;
}


//END DIALOG FULLSCREEN

.mea-maximized, .mea-fullscreen {
  .mea-dialog-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (min-width: 769px) {
    .mea-dialog-container {
      width: 769px;
    }
  }
  @media (min-width: 1025px) {
    .mea-dialog-container {
      width: 1025px;
    }
  }
  @media (min-width: 1200px) {
    .mea-dialog-container {
      width: 1200px;
    }
  }
}


//CUSTOM XXL SIZE MODAL
.modal-xxl {
  max-width: 100%;
  width: auto;
}

@media (min-width: 576px) {
  .modal-xxl {
    max-width: 95%;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .modal-xxl {
    max-width: 95%;
    width: auto;
  }
}

@media (min-width: 1600px) {
  .modal-xxl {
    max-width: 1500px;
    width: auto;
  }
}

//MODAL FULLSCREEN BS5
.modal-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  display: block;
  margin:0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-fullscreen .modal {
  width: 100vw;
  height: 100vh;
}

.modal-fullscreen .modal-content {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}







@import "messages";