//.modal {
//  .modal-content {
//    border-radius: 16px;
//  }
//
//  .modal-dark {
//
//    @extend .dark-version-content;
//
//    .modal-header {
//      margin-top: 24px;
//      color: #ffffff;
//      border: none;
//      .modal-title {
//        font-family: $fontFamilyTitle;
//        font-size: 24px;
//        font-weight: 800;
//      }
//
//
//      .close {
//        color: #ffffff;
//        text-shadow: none;
//        margin-left: auto;
//      }
//    }
//
//    .modal-footer {
//      border-top: 1px solid #383E49;
//
//      .btn.btn-danger {
//        @extend .btn-outline-primary;
//      }
//
//    }
//
//    .modal-content {
//      //background: #474A59;
//      background: $shade1;
//
//
//      .loginBody {
//        .card-footer {
//          background-color: transparent;
//        }
//      }
//    }
//  }
//}
//
//
//
//.modal-dark {
//  .modalAccordion {
//    .card-header {
//      background: #00000030;
//      a {
//        display: flex;
//        align-items: center;
//        i {
//          font-size: 1.5rem;
//        }
//      }
//
//    }
//    .card-body {
//      background: #243a5173;;
//      p {
//        font-size: 0.875em;
//        font-weight: 400;
//        margin-bottom: 0.250rem;
//
//        a {
//          color: #ffffff !important;
//        }
//      }
//    }
//  }
//}
//
//.modal {
//  .profileSection {
//    .sectionContentTitle {
//      font-weight: 500;
//      font-family: $fontFamilyTitle;
//      font-size: 1.1rem;
//    }
//    #profile_content {
//      .content-header.mea-menu-edit {
//        margin-top: 0;
//      }
//      .navbar {
//        background: none;
//        .navbar-header {
//          padding: 0 !important;
//          h1 {
//            font-weight: 500;
//            font-family: $fontFamilyTitle;
//            margin-top: 0;
//            margin-bottom: 1rem;
//            font-size: 1.2rem;
//          }
//        }
//      }
//      h4.card-title {
//        font-size: 1rem;
//        font-weight: 400;
//      }
//
//    }
//  }
//}


.modal {
  .modal-dialog {
    .mea-security-block {
      .card {
        border-radius: 16px;
      }

      .card {
        .modalCardImg {
          background: linear-gradient(180deg, rgba(1, 162, 74, 0) 0%, #01A24A 232.25%);
          border-radius: 16px;
        }


        .modalBgImage {
          background-image: url('../../../../public/images/welcome/welcome-depositBonus.png');
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right;
          padding: 1rem;

          p {
            color: $shade7;
          }
        }
      }
    }

    &.jokerLogin {
      max-width: 395px;

      .modal-header {
        display: none;
      }

      .mea-security-block {
        flex-direction: column;
        gap: 15px;

        .card.modalCardImg {
          height: 200px;

          .modalWelcomeBlock {
            width: 45%;
          }
        }

        .logoRonyBet {
          max-width: 128px;
        }
      }
    }

    &.jokerRegister {
      .modal-header {
        display: none;
      }

      .mea-security-block {
        .card {
          .modalCardImg {
            .modalBgImage {
              background-position: bottom;
              text-align: center;
            }
          }
        }
      }
    }

    .modal-content {
      background-color: transparent;

      .modal-header {
        border: none;
      }

      .modal-body {
        padding: 0;

        .card.modalBg {
          background-color: $shade1;
        }


      }

      .modal-footer {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .jokerRegister {
    max-width: 395px;

    .mea-security-block {
      flex-direction: column;

      .bg-dark {
        margin-bottom: 1rem;
      }

      .modalBgImage {
        text-align: left !important;
        background-position: right !important;

        .modalWelcomeBlock {
          width: 45%;
        }
      }
    }
  }
}


.modal-backdrop {
  position: relative;
  --bs-backdrop-opacity: 0.75;
}

.modal-dialog-right {
  position: fixed;
  top: 10px; /* Odległość od góry */
  right: 10px; /* Odległość od prawej */
  margin: 0;
  max-width: 300px; /* Opcjonalnie: szerokość */
}