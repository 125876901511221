swiper-container {
  swiper-slide {
    &.active {
      .btn {
        @extend .btn-outline-primary
      }

    }
    &:hover {
      .btn {
      @extend .btn-outline-primary
    }
    }
  }
}

.swiperGamesGrid {
  swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  swiper-slide {
    height: calc((100% - 30px) / 2) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

swiper-container::part(button-prev), swiper-container::part(button-next)  {
  width: 34px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #507585;
  background: #A28EB1;
  color: #FFFFFF;
}