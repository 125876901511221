//HEADING SECTION
.heading-section{
  font-size: 28px;
  color: $black;
  small{
    font-size: 18px;
  }
}




.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}


//SIDEBAR
.wrapper {
  width: 100%;
}

#sidebar {
  min-width: 300px;
  max-width: 300px;
  color: #fff;
  transition: all 0.3s;
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: 8px;
  background: $bgBlock;
  font-family: $fontFamilyRegular;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;


  &.active{
    margin-left: -300px;
  }


  ul{
    li {

      a {
        height: auto;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        padding: 10px 16px;
        color: $shade9;
        border: 1px solid transparent;
        border-radius: 8px;
        background-color: transparent !important;
        align-items: center;

        i {
          margin-right: 5px !important;
          font-size: 0.8rem !important;
          transition: .3s all linear;
          color: $shade7;
        }
      }
      .btn.btn-toggle {
        color: #FFFFFF;
      }

      .btn-toggle-nav li a:hover, .btn-toggle-nav li a.active {
        background: #95447C !important;
        color: #FFFFFF;

        i {
          color: $color-primary !important;
        }
      }
    }

  }
  @include media-breakpoint-down(md){
    margin-left: -300px;
    &.active {
      margin-left: 0;
    }
  }


  .sidebarContent {
    background-color: $shade0;
    border-radius: 12px;
    input, .input-group-text {
      background-color: $shade0 !important;
      color: $shade4 !important;
      border: transparent;
    }
    .form-control::placeholder {
      color: $shade4 !important;
    }
    .card {
      background-color: $background;
      .sidebarBgImage {
        min-height: 52px;
        background-repeat: no-repeat;
        background-size: auto;
        display: flex;
        align-items: center;
        border: 3px solid $background;
      }
      p {
        font-weight: 600;
        padding-right: 15px;
      }
    }
  }

}


a[data-toggle="collapse"] {
  position: relative;
}

//.dropdown-toggle::after {
//  display: block;
//  position: absolute;
//  top: 50%;
//  right: 0;
//  transform: translateY(-50%);
//}

#sidebarCollapse{
  span{
    @include media-breakpoint-down(md){
      display: none;
    }
  }
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#pageContent {
  width: calc(100% - 300px);
  //background-image: url("../../../../public/images/background/pattern1.webp");
  background-attachment: fixed;
  background-color: #12071B;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}


#pageContent.active  {
  width: 100%;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  //#sidebar {
  //  margin-left: -250px;
  //}
  #sidebar.active {
    margin-left: 0;
  }
  #pageContent, #pageContent {
    width: 100%;
  }

  #sidebarCollapse span {
    display: none;
  }
  .nav.navbar-nav {
    width: auto;
  }
}

@media (min-width: 769px) {
  .nav.navbar-nav {
    width: 100%;
  }
}
