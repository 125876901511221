.wallet-section {
  //max-height: 630px;
  //overflow: hidden;
  .wallet-paymentMethod {
    .input-group {
      background-color: $shade1 !important;
      border-radius: 8px;
      i {
        color: $shade3
      }
      .input-group-text, .form-control {
        background-color: $shade1 !important;
      }
    }

    .form-check-input {
      width: 20px;
      height: 20px;
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba($greenDark, 0.25);

      }
    }
    .nav-tabs {
      .nav-item {
        .nav-link {
          border-color: transparent;
          border-bottom: 3px solid $shade3;
          color: $shade6;
          border-radius: 0;
          &.active {
            border-bottom: 3px solid $purpleStroke;
            color: #FFFFFF;
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        max-height: 430px;
        overflow-y: auto;
      }
      /* Ukrycie paska przewijania w Chrome, Edge i Safari */
      .tab-pane::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      /* Ukrycie paska przewijania w Firefox */
      .tab-pane {
        scrollbar-width: none;
      }
    }

  }

  .wallet-paymentAction {

  }
}

