header {
  img.categoryImg {
    max-height: 25px;
    max-width: 25px;
  }

  .button-collapse {
    height: 38px !important;
    margin-left: 175px;
    @extend .btn-md;
  }
}

nav.navbar {
  background-color: $background !important;

  .btn, .infoBalance {
    font-weight: 700;
    font-size: 16px;
    height: 48px;
    border-radius: 10px;
    font-family: $fontFamilyRegular;
    display: flex;
    align-items: center;
  }

  .infoBalance {
    margin: 0.375rem;
  }

  .btn-flat:hover, .btn-flat:focus {
    border-color: $color-primary;
  }



}

.dropdown-menu {
  background-color: $background !important;
  min-width: 1rem;
  border-radius: 6px;
  padding: 6px !important;

  a {
    font-size: 1rem;
    font-weight: 400;
    font-family: $fontFamilyRegular;
    color: #FFFFFF !important;

    img.img-flag {
      width: 32px;
    }
  }
}

.dropdown-menu {
  padding: 0 !important;
  border-radius: 0;
}

.dropdown-menu .dropdown-submenu .dropdown-menu {
  li:first-child a, li:last-child a, li:last-child:first-child a {
    border-radius: 0 !important;
  }
}

.dropdown {
  a {
    display: flex;
    align-items: center;
  }
}

.dropdown.w-100 {
  .dropdown-toggle::after {
    margin-left: auto;
  }
}


.infoBalance {
  .btn {
    height: 34px !important;
    gap: 8px;
    border-radius: 8px !important;
    padding: 8px 12px;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}


.dropdown-menu {
  .nav-item, .dropdown-submenu {
    a {
      background-color: inherit;
      color: #fff;
      border-color: transparent !important;
      padding: 6px;
      border-radius: 6px;

      &:hover, &:focus, &:active {
        background-color: darken($secondary-color, 10%) !important;
      }
    }
  }
}


@media (min-width: 991px) and (max-width: 1024px) {
  nav.navbar {
    ul.navbar-nav {
      li {
        .btn {
          font-size: 0.925rem;
        }
      }
    }
  }
}


@media (max-width: 991px) {

  nav.navbar {

    ul.navbar-nav {
      li {
        a:not(.dropdown-item), .infoBalance {

          span.navItemIconText {
            display: none;
          }

          span.navItemIcon {
            display: block !important;
          }
        }

      }
    }
  }
}

@media (max-width: 768px) {
  header {
    .logo-wrapper {
      margin-right: auto;
    }

    .navbar {
      justify-content: start;
    }

    .button-collapse {
      margin-left: 1rem;
      background-color: $shade0;
      color: #fff;
      border-color: transparent !important;

      &:hover, &:focus {
        background-color: darken($shade0, 10%) !important;
      }

      &:active {
        background-color: lighten($shade0, 10%) !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .fixed-sn header nav.navbar ul.nav-flex-icons {
    flex-direction: row;
  }
}

.nav.navbar-nav {
  gap: 9px;
  align-items: center;
  flex-direction: row;
}


.side-nav .mea-lang-switch .dropdown-menu .dropdown-item img, .mea-lang-btn img, .dropdown-menu li img {
  width: 20px;
}

.mea-lang-switch {

  ul {
    background: $background;

    li {
      a {
        color: #FFFFFF;
        text-decoration: none !important;
      }
    }
  }
}