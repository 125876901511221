@import "skin";
@import "~bootstrap";
@import "~select2/dist/css/select2.min.css";

// Fontawesome 5
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts' !default;
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/v4-shims.scss';
//-- Fontawesome 5
// CUSTOM
@import "@fontsource/lexend";
@import "proxima-nova-font/dist/fonts.css";


@import "~@fontsource/poppins";
@import "~@fontsource/montserrat";

@import 'swiper/swiper-bundle.css';




@import "aos/dist/aos.css";
//@import "virtual-keyboard/dist/css/keyboard.min.css";
//@import "virtual-keyboard/dist/css/keyboard-dark.min.css";

// MDBootstrap
$roboto-font-path: '~mdbootstrap-pro/font/roboto/' !default;
$image-path: '~mdbootstrap-pro/img' !default;
//@import '~mdbootstrap-pro/scss/mdb';
//@import '~mdbootstrap-pro/scss/addons/datatables';
//@import '~mdbootstrap-pro/scss/addons/datatables-select';
//@import '~mdbootstrap-pro/scss/addons/directives';
//@import '~mdbootstrap-pro/scss/addons/flags';
//@import '~mdbootstrap-pro/scss/addons/hierarchical-display';
//@import '~mdbootstrap-pro/scss/addons-pro/cards-extended';
//@import '~mdbootstrap-pro/scss/addons-pro/chat';
//@import '~mdbootstrap-pro/scss/addons-pro/multi-range';
//@import '~mdbootstrap-pro/scss/addons-pro/steppers';
//@import '~mdbootstrap-pro/scss/addons-pro/timeline';

//@import "./../../vendor/Mea/CoreBundle/Resources/webpack/css/mdb4";
@import "./../../vendor/Mea/CoreBundle/Resources/webpack/css/meaindicator";
//@import "./../../vendor/Mea/CoreBundle/Resources/webpack/css/social_buttons";
//@import "./../../vendor/Mea/CoreBundle/Resources/webpack/css/forms.scss";



//@import "vendor/snake";

//@import "wow.js/css/site.css";
@import "wow.js/css/libs/animate.css";
//.wow {
//  visibility: hidden;
//}


//ukrycie input number wiecej

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-ms-clear {
      display: none;
    }

// iPhone X "notch" fuckery
//body {
//  padding-left: env(safe-area-inset-left);
//  padding-right: env(safe-area-inset-right);
//}

[data-mea-type="like"].liked{
  background-color: green!important;
}

//[data-mea-type="like"].liked i {
//  color: green!important;
//}



// Fix rounded buttons in MDBootstrap v4.5 and above
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
//-- MDBootstrap

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//Font for card headings
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./../../../vendor/Mea/CoreBundle/Resources/webpack/css/dialog";
//@import "./../../../vendor/Mea/CoreBundle/Resources/webpack/css/dialogMDB";
//@import "./../../../vendor/Mea/CoreBundle/Resources/webpack/css/sonata_admin";

@import "vendor/grid";
@import "vendor/main";
@import "vendor/buttons";
//@import "vendor/inputEffects";
//@import "vendor/sideNavbar";
@import "vendor/sideNavbarBS5";
@import "vendor/headerNavbar";
@import "vendor/cards";

//@import "vendor/scroll";
//@import "vendor/carousel";
@import "vendor/swiper";
//@import "vendor/animations";
@import "vendor/forms";
@import "vendor/wallet";
@import "vendor/tables";

@import "vendor/indicator";
@import "vendor/tabs";
@import "vendor/keyboard";
@import "vendor/modal";
@import "vendor/articles";
@import "vendor/offcanvas";
@import "vendor/accordion";
@import "vendor/gamesList";

form label.required:after{
  //color: #42275a8c !important;
  content: "*" !important;
  top: 0 !important;
  opacity: 1 !important;
  position: relative !important;

}