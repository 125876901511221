.ui-keyboard-keyset {
  //display: grid !important;
  //grid-template-columns: auto auto auto auto;
  //grid-gap: 4px;
  position: absolute;
  background: #333333;
  padding: 0.25rem;
  border-radius: 5px;
  z-index: 9999;

  .ui-keyboard-button {
    color: #ffffff;
    width: 65px !important;
    height: 65px !important;
    max-width: 100% !important;
    max-height: 100% !important;
    text-align: center;
    //font-size: 1.25rem;
    background: rgba(0,0,0,.5);
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 5px;
    padding: 5px;
    margin: 0.125rem;
    position: relative;
  }


  .ui-keyboard-space {
    width: 205px !important;
  }

  .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border-bottom: 1px solid #734b6d;
    background: #42275a ;
    font-weight: bold;
    color: #ffffff;
  }

}


.ui-keyboard-button:after {
  content: "";
  background: rgba(66, 39, 90, 0.25);
  display: block;
  position: absolute;
  opacity: 0;
  transition: all 0.8s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ui-keyboard-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

@media all and (max-width: 1199px) {
  .ui-keyboard-keyset {
    .ui-keyboard-button {
      width: 45px !important;
      height: 45px !important;
    }
  }
}

@media all and (max-width: 991px) {
  .ui-keyboard-keyset {
    .ui-keyboard-button {
      width: 35px !important;
      height: 35px !important;
    }
  }
}