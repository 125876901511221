@media only screen and (min-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

}


.w-5 { width: 5% !important; }
.w-10 { width: 10% !important; }
.w-15 { width: 15% !important; }
.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-35 { width: 35% !important; }
.w-40 { width: 40% !important; }
.w-45 { width: 45% !important; }
.w-55 { width: 55% !important; }
.w-60 { width: 60% !important; }
.w-65 { width: 65% !important; }
.w-70 { width: 70% !important; }
.w-80 { width: 80% !important; }
.w-85 { width: 85% !important; }
.w-90 { width: 90% !important; }
.w-95 { width: 95% !important; }


.m-n4,
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.m-n4,
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.m-n4,
.ms-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n4,
.me-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}


.m-6,
.mt-6,
.my-6 {
  margin-top: 6rem !important;
}

.m-n6,
.mt-n6,
.my-n6 {
  margin-top: -6rem !important;
}

.m-6,
.mb-6,
.my-6 {
  margin-bottom: 6rem !important;
}

.m-n6,
.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important;
}

.m-6,
.ms-6,
.mx-6 {
  margin-left: 6rem !important;
}

.m-n6,
.ms-n6,
.mx-n6 {
  margin-left: -6rem !important;
}

.m-6,
.me-6,
.mx-6 {
  margin-right: 6rem !important;
}

.m-n6,
.me-n6,
.mx-n6 {
  margin-right: -6rem !important;
}

.p-6,
.pt-6,
.py-6 {
  padding-top: 6rem !important;
}

.p-6,
.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.p-6,
.ps-6,
.px-6 {
  padding-left: 6rem !important;
}

.p-6,
.pe-6,
.px-6 {
  padding-right: 6rem !important;
}

.m-7,
.mt-7,
.my-7 {
  margin-top: 10rem !important;
}

.m-n7,
.mt-n7,
.my-n7 {
  margin-top: -10rem !important;
}

.m-7,
.mb-7,
.my-7 {
  margin-bottom: 10rem !important;
}

.m-n7,
.mb-n7,
.my-n7 {
  margin-bottom: -10rem !important;
}

.m-7,
.ms-7,
.mx-7 {
  margin-left: 10rem !important;
}

.m-n7,
.ms-n7,
.mx-n7 {
  margin-left: -10rem !important;
}

.m-7,
.me-7,
.mx-7 {
  margin-right: 10rem !important;
}

.m-n7,
.me-n7,
.mx-n7 {
  margin-right: -10rem !important;
}

.p-7,
.pt-7,
.py-7 {
  padding-top: 10rem !important;
}

.p-7,
.pb-7,
.py-7 {
  padding-bottom: 10rem !important;
}

.p-7,
.ps-7,
.px-7 {
  padding-left: 10rem !important;
}

.p-7,
.pe-7,
.px-7 {
  padding-right: 10rem !important;
}

.m-8,
.mt-8,
.my-8 {
  margin-top: 20rem !important;
}

.m-n8,
.mt-n8,
.my-n8 {
  margin-top: -20rem !important;
}

.m-8,
.mb-8,
.my-8 {
  margin-bottom: 20rem !important;
}

.m-n8,
.mb-n8,
.my-n8 {
  margin-bottom: -20rem !important;
}

.m-8,
.ms-8,
.mx-8 {
  margin-left: 20rem !important;
}

.m-n8,
.ms-n8,
.mx-n8 {
  margin-left: -20rem !important;
}

.m-8,
.me-8,
.mx-8 {
  margin-right: 20rem !important;
}

.m-n8,
.me-n8,
.mx-n8 {
  margin-right: -20rem !important;
}

.p-8,
.pt-8,
.py-8 {
  padding-top: 20rem !important;
}

.p-8,
.pb-8,
.py-8 {
  padding-bottom: 20rem !important;
}

.p-8,
.ps-8,
.px-8 {
  padding-left: 20rem !important;
}

.p-8,
.pe-8,
.px-8 {
  padding-right: 20rem !important;
}