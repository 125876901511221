.offcanvas {
  background-color: $shade2;

  .offcanvas-header {
    color: #FFFFFF;
  }

  .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23507585' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 2l12 12M14 2L2 14'/%3e%3c/svg%3e");
    width: 1.5em; /* Możesz dostosować rozmiar */
    height: 1.5em;
  }

  .offcanvas-body {
    .card {
      .card-body {
        padding: 12px;

        p {
          line-height: normal;

          &.notificationTitle {

          }

          &.notificationContent, .notificationTime {
            font-size: 13px;
          }
        }

      }
    }

    .notificationItemActive {
      background: rgba(118, 255, 84, 0.12) !important;
      .btn {
        background: rgba(118, 255, 84, 0.12) !important;
      }

      p {
        &.notificationTitle {
          color: #FFFFFF !important;
        }

        &.notificationContent, &.notificationTime {
          color: #7AA080 !important;
        }
      }
    }
  }

}