.selectDirectionalNumber {
  @extend .form-select
}


.form-control, .input-group-text, select {
  background-color: $shade0;
  color: #FFFFFF;
  border: transparent;
}

.form-control, select {
  border-radius: 8px;
  height: 50px;
}

.form-control::placeholder {
  color: $shade4;
}

.md-form {
  position: relative;
}

.form-control:focus {
  box-shadow: none;
  border: none;
  background-color: initial;
  color: #FFFFFF;
  &::placeholder {
    color: darken($shade4, 10%) !important;
  }

}

@media (max-width: 991px) {
  .mea-phone-widget {
    .col-sm-4 {
      padding-right: 0.25rem;
    }
    .col-sm-8 {
      padding-left: 0.25rem;
    }
  }
}

@media (max-width: 576px) {
  .mea-phone-widget {
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66667%;
    }
  }
}

.form-check-input:checked {
  background-color: $greenDark;
  border-color: $greenDark;
}

#accountSettings {
  .form-check-input{
    background-color: $shade3;
    border-color: $shade4;
    height: 24px;
    width: 46px;
    box-shadow: 0px 9px 10.8px 0px #173240;
  }

  .form-check-input:checked {
    background-color: $primary;
    border-color: $stroke;
  }

}

.copyInputGroup {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 66px;

  .form-control {
    height: 43px !important;
    border-radius: 6px !important;
  }

  .btn {
    display: flex;
    width: 28px;
    height: 28px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 6px !important;
    position: absolute;
    right: 10px;
    z-index: 5;
  }
}



select.form-control  {
  &:focus-within, &:focus-visible, &:target {
    background-color: $shade0 !important;
  }

}

select.form-control option:checked {
  background-color: $shade3 !important;
}


//.mea-phone-widget {
//  .md-form {
//    margin: 0 !important;
//  }
//}
//
//.form_row.md-form {
//  div[id*=Phone], div[id*=tel], div#form_usernameReset,
//  {
//    .select-wrapper input {
//      margin: 0 0 0.25rem;
//      padding: 0.6rem 0 0.4rem;
//    }
//  }
//}

//form[data-type="crud-edit-form"] {
//  .field-container {
//    padding-top: 0.5rem;
//    width: 100%;
//  }
//
//  .field-container ~ label {
//    transform: translateY(-14px) scale(.8);
//  }
//}
//
//.meaSecurityWidget {
//  .meaSecurityBody {
//
//    color: #F1F1F2;
//    padding: 2rem;
//
//    input {
//      color: #F1F1F2;
//    }
//
//    span.focus-border {
//      display: block !important;
//    }
//
//    label.active {
//      font-weight: bold;
//    }
//
//    legend {
//      font-style: normal !important;
//      font-weight: bold !important;
//      color: #aaa !important;
//    }
//
//    .md-form input:focus:not([readonly]) + label {
//      color: #c2c2c5 !important;
//      font-weight: bold;
//    }
//
//    .mea-phone-widget {
//      .md-form {
//        margin: 0 !important;
//      }
//    }
//
//    .form_row.md-form {
//      div[id*=Phone], div[id*=tel], div#form_usernameReset,
//      {
//        .select-wrapper input {
//          margin: 0 0 0.25rem;
//          padding: 0.6rem 0 0.4rem;
//        }
//      }
//    }
//
//    .md-form .form-control:not(.select-input) {
//      background-clip: padding-box;
//      border-radius: 0;
//      display: block;
//      font-size: 1rem;
//      font-weight: 400;
//      height: auto;
//      line-height: 1.5;
//      margin: 0 0 0.25rem;
//      width: 100%;
//    }
//
//    .select-wrapper {
//      > label {
//        top: 0;
//      }
//
//      > label.mdb-main-label.active {
//        color: #aaa;
//      }
//
//      .select-dropdown {
//      }
//
//      .dropdown-content.select-dropdown {
//        background: #474A59;
//
//        .search-wrap {
//        }
//
//        li {
//          background: #474A59;
//
//          span {
//            color: #aaa;
//          }
//        }
//      }
//    }
//
//    .text-muted {
//      color: #8c8ca1 !important;
//    }
//
//  }
//}
//
//
//.select2-container--default .select2-results__option--highlighted[aria-selected] {
//  background-color: $color-default;
//  color: white;
//}
//
//.select-wrapper.md-form > ul li label {
//  color: $color-default;
//}
//
//@media only screen and (max-width: 500px) {
//  [data-row-name="recaptcha"] {
//    margin-left: -1.75rem;
//  }
//}
//
//.md-form {
//  input[type="text"]:not(.browser-default):focus:not([readonly]) {
//    border-bottom: 1px solid $color-primary;
//    //box-shadow: 0 1px 0 0 $color-primary;
//  }
//}
//
//[mea-data-selector="formNumberInput"] {
//  input {
//    @extend .effect-20
//  }
//}
//
//.input_error:not(label) {
//  border-bottom: 3px solid red !important;
//}
//
//form input:required ~ label.error {
//  top: 55px !important;
//}
//
//.md-form > input[type="date"]:not(.browser-default) + label {
//  transform: translateY(-8px) scale(0.8);
//  transform-origin: 0 0;
//  font-weight: bold;
//}
//
//input[type="text"]::selection {
//  background-color: #1a1a1a;
//  color: #aaaaaa;
//}
//
//.md-form label.activeLabel {
//  font-size: 1rem;
//  transform: translateY(-14px) scale(0.8);
//}