// Your custom skin

$background: #271D2F;
$bgBlock: #1F1127;
$shade0: #2A262D;
$shade1: #291836;
$shade2: #291836;
$shade3: #5B446C;
$shade4: #A28EB1;
$shade5: #4A3957;
$shade6: #507585;
$shade7: #6E93A3;
$shade8: #8BB5CC;
$shade9: #B7DAEC;
$shade10: #FFFFFF;

$greenStable: #9DFF61;
$primary: #F9C704;
$secondary: #95447C;
$stroke: #EABA00;
$purple1: #8C79FF;
$purple2: #405EFF;
$purpleStroke: #A69FFF;
$greenInvisible: rgba(177, 255, 129, 0.15);
$greenDark: #449B00;
$pink: #FF2E6D;
$dangers: #FF6767;
$skinJoker: #171717;
$orange: #FF730D;
$pink: #FF65CB;
$green: #39BD25;


//buttons
//domyślny żółty
//ciemno filoetowy shade2
//ciemny shade0
//jasny liliowy shade4


//Colors

$color-primary: $primary;
$primary-color: $primary;
$color-primary-1: lighten($primary, 15%);
$color-primary-2: lighten($primary, 30%);
$color-primary-3: darken($primary, 15%);
$color-primary-4: darken($primary, 30%);

$color-primary-rgb: rgb(248, 169, 39);
$color-primary-rgba: rgba(248, 169, 39, 1);
$color-primary-rgba-1: rgba(mix(white, $primary, 15%), 1);



$secondary-color: $shade4;
$color-secondary: $shade4;
$color-secondary-1: lighten($shade4, 15%);
$color-secondary-2: lighten($shade4, 30%);
$color-secondary-3: darken($shade4, 15%);
$color-secondary-4: darken($shade4, 30%);

.color0 { color: $color-primary}
.color1 { color: $color-primary-1}
.color2 { color: $color-primary-2}
.color3 { color: $color-primary-3}
.color4 { color: $color-primary-4}

$third-color: $shade2;
$color-third: $shade2;
$color-third-1: lighten($shade2, 15%);
$color-third-2: lighten($shade2, 30%);
$color-third-3: darken($shade2, 15%);
$color-third-4: darken($shade2, 30%);


$color-accent: $color-primary;
$color-accent-1: $color-primary-1;
$color-accent-2: $color-primary-2;
$color-accent-3: $color-primary-3;
$color-accent-4: $color-primary-4;

$color-default: #00687e;

$fontFamilyRegular: "Lexend", sans-serif;
$fontFamilyTitle: "Proxima Nova";
$mainFontSize: 1rem;

$colorDefault: #787878;
$colorDark: #271B39;
$bgDark: #464646;
$bgDark2: #2b2b2b;
$colorLight: #A4A4A4;
$colorTitle: #323232;
$colorHeader: #E2E2E2;
$colorBcgr: #F0F0F0;

// Your custom skin
// Skins
//variables - https://mdbootstrap.com/docs/b4/jquery/css/skins/

$skins: () !default;
$skins: map-merge((
        "custom": (
                "skin-primary-color":      $color-primary,
                "skin-secondary-color":    $color-secondary,
                "skin-text-primary":       $color-primary,
                "skin-navbar":             $color-primary-3,
                "skin-footer-color":       $colorTitle,
                "skin-flat":               #fff,
                "skin-accent":             $color-third-4,
                "skin-sidenav-item":       #fff,
                "skin-sidenav-item-hover": #fff,
                "skin-gradient-start":     $color-third-4,
                "skin-gradient-end":       $color-third-1,
                "skin-mask-slight":        $color-third-1,
                "skin-mask-light":         $color-third-2,
                "skin-mask-strong":        $color-third-4,
                "skin-sn-child":           #fff,
                "skin-btn-primary":        $color-primary,
                "skin-btn-primary-hover":  $color-primary-4,
                "skin-btn-secondary":      $color-secondary,
                "skin-btn-default":        $color-default,
                "skin-text":               #fff,
        )
), $skins);

$shades: (
        0: $shade0,
        1: $shade1,
        2: $shade2,
        3: $shade3,
        4: $shade4,
        5: $shade5,
        6: $shade6,
        7: $shade7,
        8: $shade8,
        9: $shade9,
        10: $shade10
);


.text-stroke {
  color: $stroke !important;
}

.purpleGradient {
  background: linear-gradient(180deg, $purple1 0%, $purple2 100%);
}

$input-md-focus-color                        : $secondary-color !default;

.bg-default {
  background-color: $background !important;
}
.bgBlock {
  background-color: $bgBlock !important;
}

.bg-purple1 {
  background-color: $purple1 !important;
}

.bg-purple2 {
  background-color: $purple2 !important;
}




.custom-skin {
  .color-accent {
    color: $color-primary;
  }

  .color-secondary {
    color: $color-secondary-4;
  }

  .bg-secondary {
    background-color: $color-secondary-4;
  }

  .navbar .navbar-nav .nav-item .dropdown-menu a:active, .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .navbar .navbar-nav .nav-item .dropdown-menu a:hover {
    background-color: $color-primary-4 !important;
  }
}

@each $i, $color in $shades {
  .text-shade#{$i} {
    color: $color !important;
  }
}

@each $i, $color in $shades {
  .bg-shade#{$i} {
    background-color: $color !important;
  }
}


.dark-version-content {
  color: #ffffff;
  p, h1, h2, h3, h4, h5, input, textarea, select {
    color: #ffffff !important;
  }

  .card, .card-header, .card-body, .card-footer {
    background: transparent;
  }

  label, legend {
    color: #FFFFFF !important;
  }
}