.table {
  thead {
    tr {
      th {
        background-color: $shade0;
        color: $shade6;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;


      }
    }
  }

  tbody {
    tr {
      td {
        white-space: nowrap;

        .tableImgUser {
          height: 34px;
          width: 34px;
          border-radius: 8px;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 12px;
        }
      }

      td.tableType {
        img {
          width: 20px;
          height: 20px;
        }
      }

      td:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      td:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

}

.table-striped {
  > tbody {
    > tr:nth-of-type(even) > * {
      background-color: $shade0;
      color: #FFFFFF;
    }

    tr:nth-of-type(odd) > * {
      background-color: $shade1;
      color: #FFFFFF;
    }
  }
}

.tableShowMore {
  background: linear-gradient(180deg, rgba(14, 29, 40, 0.00) -15.73%, #0E1D28 46.35%);
  width: 100%;
  height: 143px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 12px 12px;

}

.typeDeposit {
  .tableStatus {
    color: $stroke !important;
  }
}

.typeWithdrawal {
  .tableStatus {
    color: $dangers !important;
  }
}

//[data-mea-type="list_filters"] {
//  padding: 0 15px;
//  color: #212529;
//
//  .btn-naked {
//    color: #212529 !important;
//  }
//
//  .box-tools-filters {
//    align-items: center;
//  }
//}
//
//[data-mea-type="sonata_list"] .box-footer {
//  background: $color-primary !important;
//}