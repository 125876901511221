body {
  font-family: $fontFamilyRegular;
  background-color: #1a1a1a;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $fontFamilyTitle !important;
}

b, strong {
  font-weight: bold;
}


@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: #{$i * 100} !important;
  }
}

.btn {
  border-radius: 16px;
  padding: 16px 24px;
  font-family: $fontFamilyRegular;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
}

.border-radius-1 {
  border-radius: 0.5rem;
}

.border-transparent {
  border-color: transparent !important;
}


.fixed-sn main, .fixed-sn .page-footer .container-fluid, .fixed-sn .page-footer .container-sm, .fixed-sn .page-footer .container-md, .fixed-sn .page-footer .container-lg, .fixed-sn .page-footer .container-xl {
  margin-right: 0;
  margin-left: 0;
}

.infinitySwitch {
  background-color: transparent !important;
}

.min-h-250 {
  min-height: 250px;
}

.min-h-380 {
  min-height: 380px;
}

.mh-50p {
  max-height: 50px;
}

.mh-300p {
  max-height: 300px;
}

.h-300p {
  height: 300px;
}

.h-260p {
  height: 260px;
}


.content-header.mea-menu-edit {
  margin-top: 2rem;
}

p {
  font-size: 14px;
}

.text-sm {
  font-size: 13px;
}

h1, .h1 {
  font-size: 56px !important;
}

h2, .h2 {
  font-size: 32px !important;
}

h3, .h3 {
  font-size: 24px !important;
}

h4, .h4 {
  font-size: 18px !important;
}

h5, .h5 {
  font-size: 16px !important;
}

h6, .h6 {
  font-size: 14px !important;
}




@media (max-width: 1400px) {
  h1, .h1 {
    font-size: 36px !important;
  }
  h2, .h2 {
    font-size: 24px !important;
  }
}

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 26px !important;
  }
}





@media (max-width: 768px) {
  h1, .h1 {
    font-size: 1.75rem !important;
  }

  h2, .h2 {
    font-size: 1.5rem !important;
  }

  h3, .h3 {
    font-size: 1.2rem !important;
  }

  h4, .h4 {
    font-size: 1rem !important;
  }

  h5, .h5 {
    font-size: 1.25rem !important;
  }

  h6, .h6 {
    font-size: 1.125rem !important;
  }
}



.footer {
  .footer-title {
    color: $shade7 !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  .footer-links {
    a {
      color: $shade7 !important;
      font-size: 14px;
      text-decoration: none;
      font-weight: 500;
      line-height: 20px;
      margin-right: 20px;
    }
  }
  .footer-bottom {
    color: $shade7;
  }

  .appStoreBlock {
    color: $shade7;
    background-color: $shade0;
    border-radius: 12px;
  }

}

//IMAGES
.logoRonyBet {
  max-width: 85px;
}

/* Ukrycie paska przewijania w Chrome, Edge i Safari */
#sidebar::-webkit-scrollbar,
.content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Ukrycie paska przewijania w Firefox */
#sidebar {
  scrollbar-width: none;
}


.sf-toolbar.sf-error-toolbar {
  display: none;
}

@for $i from 0 through 16 {
  .radius-#{$i} {
    border-radius: #{$i}px !important;
  }
}


.bgPromo {
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  &.bgBottomCenter {
    background-position: center bottom;
  }
  &.bgRightCenter {
    background-position: center right;
  }
}

.bgPromoSize1 {
  height: 380px;
}

.bgPromoSize2 {
  height: 270px;
}

.bgPromoSize3 {
  height: 420px;
}

.bgPromoSize4 {
  height: 314px;
  background-position: 70% 50%;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .bgPromoSize3 {
    height: 360px;
  }
}


@media (max-width: 991px) {
  .bgPromoSize1 {
    height: 230px;
  }

  .bgPromoSize2 {
    height: 190px;
  }

  .bgPromoSize3 {
    height: 270px;
  }

  .bgPromoSize4 {
    height: 230px;
    background-position: 60% center;
  }
}



@media (min-width: 1200px) {
  .socialMediaBlock > div {
    justify-content: end;
  }
}
