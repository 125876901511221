.gamesList {
  .card.gamesItem {
    transition: .3s all linear;
    z-index: 1;
    border: 1px solid transparent;
    background-color: transparent;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;

    .gamesInner {

      .gamesThumb {
        position: relative;

        img {
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          border-radius: 12px;
        }

        .gamesThumbInfo {
          position: absolute;
          top: 0;
          color: #ffffff;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: end;
          align-items: center;
          flex-direction: column;
          text-align: center;
          overflow: hidden;
          padding: 15px;
          font-family: $fontFamilyTitle;

          .gamesThumbInfoName {
            font-size: 1.25rem;
            line-height: 1;
            font-weight: 900;
            padding: 15px;
            position: relative;
            text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
            margin-bottom: 0;
            color: #FFFFFF !important;
          }

          .gamesThumbInfoProvider {
            text-transform: capitalize;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }


      .gamesOverlay {
        position: absolute;
        content: "";
        visibility: hidden;
        opacity: 0;
        transition: all .45s ease;
        //display: grid;
        //place-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 100%;
        height: 100%;
        background: rgba(9, 46, 0, 0.84);
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        p {
          color: #FFFFFF !important;
        }
        .gamesOverlayline {
          background: rgba(255, 255, 255, 0.12);
          width: 57px;
          height: 1px;
        }


        .gamesThumbInfoProvider {
          color: $shade7;
        }

        .btn-like, .btn-play {
          width: 32px !important;
          height: 32px !important;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;

          i {
            font-size: 14px;
            color: #ffffff
          }
        }

        .btn-secondary {
          color: $shade7 !important;
        }
      }
    }

    .gamesInner:hover .gamesOverlay {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);

    }
  }

  .gamesItem:hover {
    //transform: translateY(10px);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);
    .gamesThumb:after {
      backdrop-filter: blur(3px);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 12px;
      border: 1px solid transparent;
    }
  }

  .gamesItem:hover:after, .gamesItem:hover:before {
    opacity: 0;
    bottom: 0;
    visibility: hidden;
  }

  .gamesItem:before {
    width: 95%;
    height: 95%;
    border: 1px solid rgba(255, 255, 255, 0.04);
    bottom: -12px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    transition: all .2s ease;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.lastWinnersItem:hover {
  .mask {
    background-color: rgba(39, 27, 57, 0.3);
  }
}


