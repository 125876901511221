.card {
  border-radius: 16px;
  background-color: $shade0;
  .card-body {
    padding: 16px;
    .card-title {
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;
    }
    .card-subtitle {
      font-weight: 600;
      line-height: normal;
    }

    p, label {
      color: $shade7;
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      color: #FFFFFF;
    }
  }
  .gamesOverlay {
    .card-body {
      p {
        color: $shade10 !important;
      }
    }
  }
}

.card-grey {
  .form-control {
    border-radius: 8px;
    background-color: $shade1;
    color: #FFFFFF;
  }
  .form-control::placeholder {
    color: #426574 !important;
  }
}

//.card-link {
//  border: 1px solid transparent;
//  background-color: transparent !important;
//}
//
//.card-link:hover {
//  border: 1px solid $color-primary;
//
//  i {
//    color: $color-primary;
//  }
//}
//
////CATEGORY PANEL
//.card-image {
//  .card-link {
//    transition: .3s all linear;
//    -webkit-transition: .3s all linear;
//  }
//}
//
//img.categoryImg {
//  filter: brightness(85%) hue-rotate(220deg) saturate(100%) contrast(180%);
//  -webkit-filter: brightness(85%) hue-rotate(220deg) saturate(100%) contrast(180%);
//  -moz-filter: brightness(85%) hue-rotate(220deg) saturate(100%) contrast(180%);
//  transition: filter .3s linear;
//  -webkit-transition: filter .3s linear;
//}
//
//.categoryPanelItem .card-image:hover, .categoryHeaderIcon:hover {
//  img.categoryImg {
//    filter: brightness(75%) hue-rotate(220deg) saturate(100%) contrast(180%);
//    -webkit-filter: brightness(75%) hue-rotate(220deg) saturate(100%) contrast(180%);
//    -moz-filter: brightness(75%) hue-rotate(220deg) saturate(100%) contrast(180%);
//  }
//}
//
//.categoryPanelItem {
//  .btn.active {
//    .card-link {
//      border: 1px solid #EF8B1D;
//    }
//  }
//}
//
//.card-provider {
//    background-color: #ffffff0d;
//  }
