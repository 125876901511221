//MEA MESSAGES
#messages {
  .meaMessage {
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-header, .modal-body, .modal-footer {
        display: flex;
        padding: 0.75rem;
      }

      .modal-header {
        align-items: center;
        justify-content: start;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        .icon-box {
          border-radius: 100%;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
        }
      }
      .modal-body {
        position: relative;
        flex: 1 1 auto;
      }
      .modal-footer {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: calc(0.3rem - 1px);
        border-bottom-left-radius: calc(0.3rem - 1px);
      }
    }
  }
}