a {
  color: $color-default;
  text-decoration: none;
}


a.btn:not([href]):not([tabindex]), .custom-skin a.btn:not([href]):not([tabindex]):focus, .custom-skin a.btn:not([href]):not([tabindex]):hover {
  color: #FFFFFF;
}

.btn {
  height: 48px;
  padding: 14px 16px;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  font-family: $fontFamilyRegular;
  text-transform: none;
  font-weight: 600;
  border-radius: 5px;
  line-height: 100%;
}


.btn-xs {
  height: 34px;
  font-size: 14px;
  padding: 8px;
}

.btn-sm {
  height: 42px;
  font-size: 14px;
  padding: 12px;
}

.btn-md {
  height: 48px;
}

.btn-lg {
  height: 52px;
  font-size: 16px;
  padding: 16px 24px;
}

.btn-xl {
  height: 55px;
  font-size: 18px;
  padding: 16px 24px;
}


.third-ic {
  color: $color-third-3 !important;
}

.third:focus, .third-ic:hover {
  color: $color-third-3;
}

table.table a.btn.btn-third {
  color: #fff;
}

.btn.close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: $background !important;
  color: $shade6 !important;

  &:hover, &:focus, &:active {
    background-color: darken($background, 10%) !important;
  }
}

.btn-square {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn-social {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 8px;
  color: $shade7;
    img {
      filter: brightness(0%) invert(100%);
      -webkit-filter: brightness(0%) invert(100%);
      -moz-filter: brightness(0%) invert(100%);
    }

}

.btn-blur {
  position: relative;
  border-radius: 10px;
  border: 1.5px solid $greenInvisible;
  //background: transparent;
  background: $greenInvisible;
  color: #ffffff;
  display: flex;
  width: 100%;
  max-width: 155px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  backdrop-filter: none;

  &:hover, &:focus, &:active {
    background-color: darken($greenInvisible, 50%) !important;
    color: #ffffff !important;
    border: 1.5px solid $greenInvisible !important;
    box-shadow: none !important;
  }
}


.btn-category {
  font-size: 14px;
  font-weight: 500;
  color: $shade7 !important;
  padding: 8px 12px !important;
  border-radius: 12px;
  gap: 8px;
  line-height: 100%;
  letter-spacing: 0;

  &:hover, &:focus, &:active {
    background: linear-gradient(180deg, $purple1 0%, $purple2 100%);
    color: #fff !important;
    border: 1px solid #A69FFF;

    .categoryImg {
      transition: filter 0s ease-in-out;
    }

    .categoryImg {
      filter: brightness(0%) invert(100%);
      -webkit-filter: brightness(0%) invert(100%);
      -moz-filter: brightness(0%) invert(100%);
    }

  }
}


.btn-secondary {
  background-color: $color-secondary;
  color: #fff;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($color-secondary, 10%) !important;
  }
}


.btn-third {
  background-color: $color-third;
  color: #fff !important;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($color-third, 10%) !important;
    color: #fff !important;
  }
}


.btn-orange {
  background-color: $orange;
  color: #fff !important;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($orange, 10%) !important;
    color: #fff !important;
  }
}

.btn-pink {
  background-color: $pink;
  color: #fff;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($pink, 10%) !important;
    color: #fff !important;
  }
}

.btn-green {
  background-color: $green;
  color: #fff;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($green, 10%) !important;
    color: #fff !important;
  }
}

.btn-shade0 {
  background-color: $shade0 !important;
  color: #fff;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($shade0, 10%) !important;
    color: #fff !important;
  }
}

.btn-background {
  background-color: $background;
  color: #fff;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($background, 10%) !important;
    color: #fff !important;
  }
}

.btn-purple {
  background: linear-gradient(180deg, $purple1 0%, $purple2 100%);
  color: #fff !important;
  border: 1px solid #A69FFF;

  &:hover, &:focus, &:active {
    background: linear-gradient(180deg, darken($purple1, 10%) 0%, darken($purple2, 10%) 100%) !important;
    color: #fff !important;
  }
}

.btn-greenInvisible {
  background-color: $greenInvisible;
  color: $primary !important;
  border-color: transparent !important;

  &:hover, &:focus, &:active {
    background-color: darken($greenInvisible, 30%) !important;
    color: $primary !important;
  }
}

.navbar-nav {
  .btn.dropdown-toggle {
    padding: 14px 16px 14px 16px;

    &::after {
      right: 0;
      margin-right: 10px;
    }
  }
}


.sonata-ba-list-field-actions._action {
  .btn {
    @extend .btn-sm
  }
}

.btnFooter {
  gap: 9px;

  a.btn {
    font-size: 16px;
    border-radius: 10px;
    font-weight: 700;
  }
}



.badge-skew {
  display: inline-block;
  transform: skewX(-15deg);
  padding: 0.35rem 0.75rem;
}

.gameSwitchButtons {
  padding: 5px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .btn {
    height: 40px;
    display: flex;
    padding: 11px 26px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 6px;
    background-color: transparent;
    color: $shade6;
    border: 1px solid transparent;
    &.active {
      border: 1px solid $shade3;
      background: $shade2;
      color: #FFFFFF;
    }
  }
}

@media (max-width: 1200px) {
  .btn {
    height: 44px;
    padding: 14px;
    font-size: 14px;
  }
  nav.navbar .btn, nav.navbar .infoBalance {
    height: 40px !important;
  }
}

.iconStack {
  display: flex;
  align-items: center;
  justify-content: center;
  .iconStackOut {
    font-size: 17px;
    position: absolute;
    color: #B1FF81;
    opacity: .15;
  }
  .iconStackIn {
    font-size: 7px;
    position: absolute;
    color: #3E4639;
  }
}

.border-shade3 {
  border: 1px solid $shade3;
}

.btn-check:checked + .btn {
  background: linear-gradient(180deg, $purple1 0%, $purple2 100%);
  color: #ffffff !important;
}